import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import icon1 from "../images/tiles.svg"
import icon2 from "../images/parquet.svg"
import icon3 from "../images/glass-wall (1).svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Epopõrandad, ruumijagajad, tõstetud põrandad, lavad, platvormid." />

    <div className="banner">
      <div className="banner-fill">
        <div className="bg-color">
          <div className="container">
            <div className="row">
              <div className="banner-text text-center">
                
                <div className="intro-para text-center quote">
                  <h1>
                    Epopõrandad, tõstetud põrandad, ruumijagajad, platvormid,
                    lavad
                  </h1>
                  <p className="small-text">
                    Pakkumise saamiseks palun võtke ühendust.
                  </p>
                  <Link to="/firmast/">
                    <div className="btn get-quote">KONTAKT</div>
                  </Link>
                </div>
                <div className="mouse-hover"></div>
              </div>
              <div className="mouse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="feature" className="section-padding">
      <div className="container feature-container">
        <div className="row">
          <div className="header-section text-center">
            <h2>Meie põhitegevusalad on</h2>
            <hr className="bottom-line"></hr>
          </div>
          <div className="feature-wrapper">
            <div className="fea-img pull-left">
              <img src={icon2} alt="Epokatted" width="50"></img>
            </div>
            <div className="fea">
              <div className="heading pull-right">
                <h4>Epopõrandad, polüuretaankatted</h4>
                <p>
                  Müük, paigaldus ja hooldus. Epopõrandad on isetasanduvad või
                  liivamassiga segatud epomass katted. Epokatteid saab
                  valmistada erievates toonides, struktuuris ja kujunduses.{" "}
                </p>
              </div>
            </div>
            <div className="fea-img pull-bottom">
              <img src={icon1} alt="Tõstetud põrandad" width="60"></img>
            </div>
            <div className="fea">
              <div className="heading pull-bottom">
                <h4>Tõstetud põrandad</h4>
                <p>
                  Müük, paigaldus ja hooldus. Tõstetud põrandad (tõstpõrandad)
                  on suuremalt jaolt kasutuses serverite ja seadmete ruumides
                  või ruumides, kus on vajalik aluspõranda alla jätta ruumi
                  kaablitele või jahutusele.{" "}
                </p>
              </div>
            </div>
            <div className="fea-img pull-left">
              <img src={icon3} alt="Ruumijagajad" width="50"></img>
            </div>
            <div className="fea">
              <div className="heading pull-right">
                <h4>Siirdeseinad, voldikseinad, voldikuksed</h4>
                <p>
                  Müük, paigaldus ja hooldus.Tõstetud põrandad (tõstpõrandad) on
                  suuremalt jaolt kasutuses serverite ja seadmete ruumides või
                  ruumides.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="work-shop" className="section-padding">
      <div className="header-section text-center">
        <h2>Tehtud tööd</h2>
        <hr className="bottom-line"></hr>
      </div>
      <div className="container workshop-wrapper">
        <div className="service-box box1">
          <div className="icon-text">
            <h4 className="ser-text">Tallinna Lauluväljak</h4>
          </div>
        </div>
        <div className="service-box box2">
          <div className="icon-text">
            <h4 className="ser-text">Aqva veekeskus</h4>
          </div>
        </div>
        <div className="service-box box3">
          <div className="icon-text">
            <h4 className="ser-text">Tallinna Lennujaam</h4>
          </div>
        </div>

        <div className="service-box box4">
          <div className="icon-text">
            <h4 className="ser-text">KUMU</h4>
          </div>
        </div>
        <div className="service-box box5">
          <div className="icon-text">
            <h4 className="ser-text">Sakala toimetus</h4>
          </div>
        </div>
        <div className="service-box box6">
          <div className="icon-text">
            <h4 className="ser-text">Tallinna Sadam</h4>
          </div>
        </div>
      </div>
      <Link to="/ruumijagajad/referentsid/">
        <button className="btn workshop-btn">Vaata veel</button>
      </Link>
    </section>
  </Layout>
)

export default IndexPage
